import { Injectable } from '@angular/core';
import { SortOption, SortType } from '../deal-list';
import { CrmCardViewItem } from '@api-clients/crm-api-client';
import { parseISO } from 'date-fns';
import { ZenmodeOrderService } from 'app/+state/deals/services/zenmode-order-service.service';

@Injectable({
  providedIn: 'root',
})
export class DealListSortService {
  constructor(private zenmodeOrderService: ZenmodeOrderService) {}

  private initialSortingOptions: SortOption[] = [
    { id: SortType.LAST_TOUCH, label: 'Времени касания' },
    { id: SortType.ZENMODE, label: 'Зенмоду' },
  ];

  public getSortingOptions(): SortOption[] {
    return this.initialSortingOptions;
  }

  public sortDeals(deals: CrmCardViewItem[], sortType: SortType): CrmCardViewItem[] {
    switch (sortType) {
      case SortType.LAST_TOUCH:
        return this.sortByLastTouch(deals);
      case SortType.ZENMODE:
        return this.zenmodeOrderService.transform({ value: deals }) as CrmCardViewItem[];
      default:
        return deals;
    }
  }

  private sortByLastTouch(deals: CrmCardViewItem[]): CrmCardViewItem[] {
    return [...deals].sort((a, b) => {
      const aDateDesc = a.lastWhatsappMessage?.createdAt || a.deal.createDate;
      const bDateDesc = b.lastWhatsappMessage?.createdAt || b.deal.createDate;

      // Проверяем, есть ли у обоих сделок последнее сообщение WhatsApp
      if (a.lastWhatsappMessage && !b.lastWhatsappMessage) {
        return -1; // Сделка A должна быть выше
      } else if (!a.lastWhatsappMessage && b.lastWhatsappMessage) {
        return 1; // Сделка B должна быть выше
      }

      const aDate = parseISO(aDateDesc);
      const bDate = parseISO(bDateDesc);

      return bDate.getTime() - aDate.getTime();
    });
  }
}
