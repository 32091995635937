import { CrmTaskItem } from '@api-clients/crm-api-client';
import { CrmCardViewItem } from '@api-clients/crm-api-client/dist/models';
import { DealStatus } from '../../../models/deal-status';
import { ShadowNextTasksMap } from '../deals.interface';
import { Injectable } from '@angular/core';

enum TaskTypePriorityEnum {
  urgent,
  missed,
  calling,
  whatsapp,
}

const ClientSourcePriorityList = [75, 119, 117, 26, 79, 113, 99, 114, 23, 25, 30, 87, 127, 131];
const TIME_LIMIT_MINUTES = 30;
const CONVERSATION_STATUS_NEW = 1;
const TIME_NEAR_CURRENT_IN_MINUTES = 15;
const DEFAULT_PRIORITY = 2;
const AUTO_TOUCH_STAGE = 'autoTouch';
@Injectable({
  providedIn: 'root',
})
export class ZenmodeOrderService {
  public getDateString(date?: string, time?: string): string {
    return date && time ? `${date} ${time}` : '';
  }

  private sortByTime(a: CrmCardViewItem, b: CrmCardViewItem, shadowMap: ShadowNextTasksMap = {}): number {
    const taskA = this.getRelevantTask(a, shadowMap);
    const taskB = this.getRelevantTask(b, shadowMap);

    const dateStringA = new Date(this.getDateString(taskA?.date, taskA?.time));
    const dateStringB = new Date(this.getDateString(taskB?.date, taskB?.time));
    return dateStringB.getTime() - dateStringA.getTime();
  }

  private sortByConversationStatus(a: CrmCardViewItem, b: CrmCardViewItem): number {
    // Проверяем статус разговора
    if (a.deal.conversationStatus === 1 && b.deal.conversationStatus !== 1) return -1;
    if (b.deal.conversationStatus === 1 && a.deal.conversationStatus !== 1) return 1;

    // Если обе сделки имеют conversationStatus === 1, тогда проверяем статус сделки
    if (a.deal.conversationStatus === 1 && b.deal.conversationStatus === 1) {
      if (a.deal.status === DealStatus.new && b.deal.status !== DealStatus.new) return -1;
      if (b.deal.status === DealStatus.new && a.deal.status !== DealStatus.new) return 1;
    }

    return 0;
  }
  private getRelevantTask(
    item: CrmCardViewItem,
    shadowMap: ShadowNextTasksMap = {},
  ): CrmTaskItem | undefined {
    return shadowMap[item.card.id] || item.nextTask;
  }

  public isWithinTimeLimit(dateString: string): boolean {
    const userDate = new Date(dateString);
    const currentDate = new Date();
    const futureLimitDate = new Date(currentDate.getTime() + TIME_LIMIT_MINUTES * 60 * 1000);
    return userDate < futureLimitDate && userDate <= currentDate;
  }

  private taskPriority(taskType: TaskTypePriorityEnum | string): number {
    return taskType === TaskTypePriorityEnum.urgent || taskType === TaskTypePriorityEnum.missed
      ? 1
      : DEFAULT_PRIORITY;
  }

  private isNearCurrentTime(date: string, time: string): boolean {
    if (!date || !time) return false;
    const taskDateTime = new Date(this.getDateString(date, time));
    const now = new Date();
    const diffInMinutes = (taskDateTime.getTime() - now.getTime()) / (1000 * 60);
    return Math.abs(diffInMinutes) <= TIME_NEAR_CURRENT_IN_MINUTES;
  }

  private sortNewDeals(deals: CrmCardViewItem[]): CrmCardViewItem[] {
    return [...deals].sort((a, b) => {
      const indexA = ClientSourcePriorityList.indexOf(a.deal.clientSourceId ?? -1);
      const indexB = ClientSourcePriorityList.indexOf(b.deal.clientSourceId ?? -1);
      return indexA - indexB;
    });
  }

  private sortWorkDeals(deals: CrmCardViewItem[], shadowMap: ShadowNextTasksMap = {}): CrmCardViewItem[] {
    return [...deals]
      .filter(deal => !deal.card.isConversionExclude)
      .sort((a, b) => {
        if (a.deal.stage === AUTO_TOUCH_STAGE && b.deal.stage !== AUTO_TOUCH_STAGE) {
          return 1;
        }
        if (b.deal.stage === AUTO_TOUCH_STAGE && a.deal.stage !== AUTO_TOUCH_STAGE) {
          return -1;
        }

        if (a.nextTask || b.nextTask) {
          if (!a.nextTask) return 1;
          if (!b.nextTask) return -1;
          // Проверка близости времени задачи к текущему времени
          const isANearCurrentTime = this.isNearCurrentTime(a.nextTask.date, a.nextTask.time);
          const isBNearCurrentTime = this.isNearCurrentTime(b.nextTask.date, b.nextTask.time);
          if (isANearCurrentTime && !isBNearCurrentTime) return -1;
          if (!isANearCurrentTime && isBNearCurrentTime) return 1;

          // Проверка приоритета задачи
          const taskPriorityA = this.taskPriority(a.nextTask.type);
          const taskPriorityB = this.taskPriority(b.nextTask.type);
          if (taskPriorityA !== taskPriorityB) return taskPriorityA - taskPriorityB;
        }

        const returnTouristComparison = (a.deal.isReturnTourist ? 1 : 0) - (b.deal.isReturnTourist ? 1 : 0);
        if (returnTouristComparison !== 0) return -returnTouristComparison;

        const dateA = new Date(a.lastSearchRequest?.dateFrom ?? 0);
        const dateB = new Date(b.lastSearchRequest?.dateFrom ?? 0);
        const dateComparison = dateA.getTime() - dateB.getTime();
        if (dateComparison !== 0) return dateComparison;

        const timeComparison = this.sortByTime(a, b, shadowMap);
        if (timeComparison !== 0) return timeComparison;

        return 0;
      });
  }

  transform(input: { value: CrmCardViewItem[]; shadowNextTasksMap?: ShadowNextTasksMap }): CrmCardViewItem[] {
    const { value, shadowNextTasksMap = {} } = input;
    if (!value) return [];

    const filteredDeals = value.filter(item => {
      const relevantTask = this.getRelevantTask(item, shadowNextTasksMap);

      const isRelevantTaskInFuture =
        relevantTask?.date &&
        !this.isWithinTimeLimit(this.getDateString(relevantTask.date, relevantTask.time));

      const isNextTaskInFuture =
        item.nextTask?.date &&
        !this.isWithinTimeLimit(this.getDateString(item.nextTask.date, item.nextTask.time));

      return (
        item?.deal?.conversationStatus === CONVERSATION_STATUS_NEW ||
        (!isRelevantTaskInFuture && !isNextTaskInFuture)
      );
    });

    const newDeals = this.sortNewDeals(filteredDeals.filter(item => item.deal.status === DealStatus.new));
    const workDeals = this.sortWorkDeals(
      filteredDeals.filter(item => item.deal.status === DealStatus.work),
      shadowNextTasksMap,
    );
    const postSaleDeals = filteredDeals.filter(item => item.deal.status === DealStatus.postSale);
    const mergedDeals = [...newDeals, ...workDeals, ...postSaleDeals];

    return mergedDeals.sort((a, b) => this.sortByConversationStatus(a, b));
  }
}
